import BandwagonDotCom from "../components/BandwagonDotCom"
import BrandGrid from '../components/BrandGrid'
import Hero from "../components/Hero"
import MobileMenu from "../components/MobileMenu"
import React, { useState, useEffect } from "react"
import StandardBlock from '../components/StandardBlock'
import Typist from 'react-typist';
import WhoWeAre from "../components/WhoWeAre"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import './index.scss';
import 'react-typist/dist/Typist.css'

const Index = () => {
    const [ toggleTyping, setToggleTyping ] = useState(true);

    useEffect(() => {
        if (!toggleTyping) {
            setToggleTyping(true);
        }
    }, [toggleTyping]);

    return <BandwagonDotCom>
      <MobileMenu>
        <Link to="/">Home</Link>
        <Link className="hiring" to="https://bandwagon.breezy.hr/">We're&nbsp;Hiring!</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/team">Team</Link>
      </MobileMenu>
      <Hero>
          <h1>
            {toggleTyping && <Typist onTypingDone={() => setToggleTyping(false)}>
                <span>Creating.</span>
                <Typist.Backspace count={9} delay={3000} />
                <span>Connecting.</span>
                <Typist.Backspace count={11} delay={3000} />
                <span>Monetizing.</span>
                <Typist.Backspace count={11} delay={3000} />
            </Typist>}
          </h1>
          <h1>Communities.</h1>
          <p>
              Bandwagon is a Greenville, SC based experience technology company
              that delivers solutions to elevate the experience of fans and
              event attendees.
          </p>
          <Link to="/contact" className="get-in-touch">Get in touch</Link>
      </Hero>
      <WhoWeAre>
          <h1>Our Why</h1>
          <p>
              Bandwagon believes that every consumer is a fan and every fan is
              now a content creator with limitless technology sitting in their
              phones. We create opportunities for fans and consumers to connect
              with their favorite entertainers and brands by allowing them to
              mint moments, memories, and experiences. As they seek out ways to
              not only preserve their favorite experiences but also ensure, with
              cryptographic security, that they are the true creator and owner
              of this user-generated content.
          </p>
      </WhoWeAre>
      <StandardBlock className="circles-bg-1" contentSide="right">
        <h1>Technology</h1>
        <p>
            For the first time ever, brands can access and purchase the
            user-generated content from their events with ease. Our Proof of
            Experience™ blockchain provides peace of mind as we use real time
            data to augment the content.
        </p>
        <p>
            We’re partnering with Palm to deliver user-generated content based
            NFTs in an environmentally friendly way. Our experience with
            Hyperledger Fabric distributed databases and homomorphic encryption
            positions us as the team to lead in this market.
        </p>
      </StandardBlock>
      <StandardBlock className="circles-bg-2" contentSide="left">
        <h1>Proof of Experience™</h1>
        <p>
            Through our <em>Proof of Experience™</em>  blockchain, we create
            incentives for the entire value chain. From the fan capturing the
            content to the artist or athlete whose name, image, and likeness are
            being captured, we can build in royalties that compensate and reward
            all parties.
        </p>
        <p>
            The <em>Proof of Experience™</em> blockchain enables us to do
            something that has never been done before - allow fans to connect
            directly with their favorite artists, athletes, teams, and
            entertainers by not only letting them own a piece of history but
            empowering them to create it.
        </p>
      </StandardBlock>
      <BrandGrid>
        <h1>Our Brands</h1>
        <article>
            <a id="idealSeatBrand" href="https://idealseat.com">
                <StaticImage
                  src="../images/is_logo.png"
                  alt="IdealSeat Logo"
                />
            </a>
            <p>
                IdealSeat is a white-label ticketing platform for
                non-traditional  event organizers. Whether you are a content
                creator trying to connect with your audience digitally or a
                bookstore planning for an author signing while taking COVID-19
                safety measures, IdealSeat was built for you.
            </p>
            <p>
                Have customers buy tickets to your events, on a contained and
                branded site, without the distraction of seeing other competing
                events. We built this product with simplicity and security in
                mind.
            </p>
        </article>
        <article>
            <a id="poetBrand" href="https://poe.xyz">
                <StaticImage
                  src="../images/poet_logo.png"
                  alt="POET Logo"
                />
            </a>
            <p>
                A POET is a Proof of Experience™ Token that can document and
                represent the most important and interesting moments of your
                life.
            </p>
            <p>
                From everyday moments like dinner with friends to life-changing
                experiences like traveling the world, you can preserve and share
                these moments, memories, and experiences as NFTs (non-fungible
                tokens).
            </p>
            <p>
                As you create and collect experiences, you are able to use them
                to token-gate messaging platforms like Discord and Telegram as
                well as unlock private events to your favorite event organizers
                and entertainers using IdealSeat.
            </p>
        </article>
      </BrandGrid>
    </BandwagonDotCom>;
};

export default Index
