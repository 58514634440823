import React from "react"

import './standard-block.scss';

const StandardBlock = ({ children, className = '', contentSide }) => {
    const additionalStyles = {
        left: 'standard-block-left-content',
        right: 'standard-block-right-content'
    }[contentSide] || 'standard-block-left-content';

    const classes = `${className} block standard-block ${additionalStyles}`;
    return <div class={classes}>
        <div class="standard-block-content-half">
            <div class="standard-block-content">
                {children}
            </div>
        </div>
    </div>;
};

export default StandardBlock;
