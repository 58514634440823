import React from "react"

import './brand-grid.scss';

const BrandGrid = ({ children }) => {
    const childArray = React.Children.toArray(children);
    const headline = childArray.shift();

    return <div id="brand-grid-block" className="block">
        {headline}
        <div id="brand-grid-block-grid">
            {childArray.map(gridCard)}
        </div>
    </div>;
};

function gridCard(entry) {
    const childArray = React.Children.toArray(entry.props.children);
    const headline = childArray.shift();

    return <div class="brand-grid-entry">
        <div class="brand-grid-entry-title"> {headline} </div>
        <div>
            {childArray}
        </div>
    </div>;
};

export default BrandGrid;
